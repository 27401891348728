/**
 * author: jialin
 * date: 2020/04/12
 * desc: axios
 */
 import axios from 'axios';
 import { createBrowserHistory } from 'history';
 
 const history = createBrowserHistory();
 
 const baseUrl = '/';
 const instance = axios.create({
	 baseUrl: baseUrl,
	 headers: {
		 'Content-Type': 'application/json',
		 'Access-Control-Allow-Origin': '*'
	 },
	 withCredentials: true,
	// timeout: 300000
 });
 
 //after get some response
 instance.interceptors.response.use(
	 response => {
		 if (response.status === 200 && response.data !== undefined) {
			 let status;
			 if (response.data.status === 0) {
				 //  精准的交互失败处理
				 status = 'Fail';
			 } else if (response.data.status === 403) {
				 // Cookies.remove('token');
				 sessionStorage.removeItem('token');
				 //token过期需重新登录
				 // 可以直接跳转到登录页面，重新登录获取 token
				 //TODO
				 // location.href = '/login';
			 } else {
				 status = 'Succeed';
			 }
			 //  ajax反馈输出
			 // eslint-disable-next-line no-console
			 console.info('%cAjax ' + status + '%cMsg ' + response.data.msg + '%cAt ' + new Date().toLocaleString() + ' %c', 'background:' + (response.data.code === 1 ? '#5dbe3b' : 'orange') + ';color:white;border-radius:.5em 0 0 .5em;padding-left:1em;padding-right:1em', 'background:' + (response.data.code === 1 ? '#4e89ea' : 'orange') + ';color:white;padding-left:1em;padding-right:1em', 'background:' + (response.data.code === 1 ? '#c7c7ff' : 'orange') + ';color:white;border-radius:0 .5em .5em 0;padding-left:1em;padding-right:1em', response.data.data);
			 return response;
		 } else {
			 // @todo 修复交互链接失败
			 const err = new Error(response['msg']);
 
			 err.data = response.data;
			 err.response = response;
			 throw err;
		 }
	 },
	 err => {
		 //这里是返回状态码不为200时候的错误处理
		 if (err) {
			 if (err.response) {
				 switch (err.response.status) {
				 case 400:
					 
					 localStorage.removeItem('token');
					 history.push('/login');
					 err.message = 'Please note that check!!!';
					 break;
 
				 case 401:
					 // when token expire remove token the redirect to login page.
					 localStorage.removeItem('token');
					 history.push('/login');
					 err.message = 'Your session has expired, please login again';
					 break;
 
				 case 403:
					 err.message = 'Access Denied';
					 break;
 
				 case 404:
					 err.message = `Request address error: ${err.response.config.url}`;
					 break;
 
				 case 408:
					 err.message = 'The request timeout';
					 break;
 
				 case 500:
					 err.message = 'Server internal error';
					 break;
 
				 case 501:
					 err.message = 'Service not implemented';
					 break;
 
				 case 502:
					 err.message = 'Bad gateway';
					 break;
 
				 case 503:
					 err.message = 'Service unavailable';
					 break;
 
				 case 504:
					 err.message = 'Gateway timeout';
					 break;
 
				 case 505:
					 err.message = 'The HTTP version is not supported';
					 break;
 
				 default:
					 err.message = 'Unable to connect to server';
				 }
			 } else {
				 err.message = 'Unable to connect to server';
			 }
		 } else {
			 // eslint-disable-next-line no-console
			 console.log('Program Exception');
		 }
 
		 if (err.message) {
			 // eslint-disable-next-line no-console
			 console.error(err.message);
		 }
		 return Promise.reject(err);
	 }
 );
 
 export default instance;
 