import {
  FETCH_SALES_INVOICES,
  CLEAR_SALES_INVOICES,
  RETAIN_SALES_INVOICES_STATE,
  SET_SALES_INVOICES_PAGE_SIZE,
  SET_SALES_INVOICES_PAGE_NO,
  SET_SALES_INVOICES_SEARCH_FILTERS,
  FETCH_SALES_INVOICE_DETAILS,
  RETAIN_SALES_INVOICE_DETAILS_STATE,
  SET_RETAIN_SALES_INVOICE_DETAILS_STATE,
  INITIAL_SALES_INVOICE_STATE,
  SET_INVOICE_FILTER_VALUES
} from './SalesInvoicesConstants';

const initialState = {
  salesInvoicesList: [],
  retainState: false,
  retainStateInvoiceDetails: false,
  totalCount: 0,
  totalPages: 0,
  pageSize: 50,
  currentPage: 1,
  searchFilters: '',
  salesInvoiceDetails: '',
  invoiceFilterValues: {
    increment_ids: '',
    invoice_date_from: '',
    invoice_date_to: ''
  }
};

export default function SalesInvoicesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_SALES_INVOICES:
      return {
        ...state,
        salesInvoicesList: [
          ...state.salesInvoicesList,
          ...payload.salesInvoicesList
        ],
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage
      };

      case SET_INVOICE_FILTER_VALUES:
        return {
          ...state,
          invoiceFilterValues: payload,
          retainState: true
        };

    case CLEAR_SALES_INVOICES:
      return {
        ...state,
        salesInvoicesList: []
        // currentPage: 1
      };

    case SET_SALES_INVOICES_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };
    case SET_SALES_INVOICES_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload
      };
    case SET_SALES_INVOICES_SEARCH_FILTERS:
      return {
        ...state,
        retainState: false,
        searchFilters: payload,
        currentPage: 1
      };
    case FETCH_SALES_INVOICE_DETAILS:
      return {
        ...state,
        salesInvoiceDetails: payload.salesInvoiceDetails
      };
    case RETAIN_SALES_INVOICES_STATE:
      return {
        ...state,
        retainState: true
      };
    case RETAIN_SALES_INVOICE_DETAILS_STATE:
      return {
        ...state,
        retainStateInvoiceDetails: true
      };
    case SET_RETAIN_SALES_INVOICE_DETAILS_STATE:
      return {
        ...state,
        retainStateInvoiceDetails: false
      };
    case INITIAL_SALES_INVOICE_STATE:
      return {
        ...state,
        salesInvoicesList: [],
        retainState: false,
        retainStateInvoiceDetails: false,
        totalCount: 0,
        totalPages: 0,
        pageSize: 50,
        currentPage: 1,
        searchFilters: '',
        salesInvoiceDetails: ''
      };
    default:
      return state;
  }
}
