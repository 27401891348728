import {
    UPLOAD_RESPONSE,
    CLEAR_RESPONSE
  } from './SalesUploadShipmentsConstants';
  
  const initialState = {
    responseResult: '',
    isSubmitted: false
   
  };
  
  
  export default function SalesUploadShipmentsReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
     
      case UPLOAD_RESPONSE:
        return {
          ...state,
          responseResult: payload.resMessage,
          resWarn:payload.resWarn,
          isSubmitted: true
        };
        case CLEAR_RESPONSE:
          return {
            ...state,
            responseResult: '',
            isSubmitted: false
          };
     
      default:
        return state;
    }
  }
  
  