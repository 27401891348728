import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import HeaderDots from '../../layout-components/HeaderDots';

import HeaderUserbox from '../../layout-components/HeaderUserbox';

import { PieChart } from 'react-feather';

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    titleHeading,
    showFeatureItems,
    currentThemeColor
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  var shadowColor = 'app-header app-header--shadow';
  if(currentThemeColor === "green") shadowColor = 'app-header app-header--shadow-green';

  return (
    <>
      <div
        className={clsx(shadowColor, {
          //shadowColor : headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box mr-3">
              <span className="hamburger-inner" />
            </span>
          </button>
          <div>
            {/* <div className="app-page-title--first"> */}

            {/*   <div className="app-page-title--iconbox d-50">
                  <div className="d-70 d-flex align-items-center justify-content-center">
                    <PieChart className="display-2 text-primary" />
                  </div>
                </div> */}

            <div className="app-page-title--heading">
              <h1>{titleHeading}</h1>
            </div>
            {/* </div> */}
          </div>{' '}
          {/* <HeaderSearch /> */}
          {/* <HeaderMenu /> */}
        </div>
        <div className="app-header--pane">
          {showFeatureItems && <HeaderDots />}
          <HeaderUserbox />
        
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  showFeatureItems: state.ThemeOptions.showFeatureItems,
  currentThemeColor: state.ThemeOptions.currentThemeColor
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
