import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { verifyAuth } from '../app/auth/authActions';
import reduxReset from 'redux-reset'
export const history = createBrowserHistory();


export default function configureStore() {
  const store = createStore(
    rootReducer(history),
    composeWithDevTools(applyMiddleware(thunk), reduxReset())
  );

  store.dispatch({
    type: 'RESET'
  })
  store.dispatch(verifyAuth());

  //store.subscribe(() => saveToLocalStorage(store.getState()));

  return store;
}

/* function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(
      state.productCatalogue.productToImport
    );
    localStorage.setItem('selectedProduct', serializedState);
  } catch (e) {
    console.log(e);
  }
}
 */
