import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Alert } from "reactstrap";
import { getUserInfo, login, signOutUser } from "./authActions";

import { Row, Col, Container, Label, Button } from "reactstrap";

import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
// import DSZ_Supplier_Terms from "../../assets/files/DSZ_Supplier_Terms.pdf";
import { saveAs } from "file-saver";
import illustration1 from "../../assets/images/logo.png";
import illustrationPurple from "../../assets/images/logo_new.png";
import { priceReviewPeriod } from "../VendorSystem/Catalog/PriceReview/PriceReviewActions";

export default function Login(props) {
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [invoiceRedirect, setInvoiceRedirect] = useState(false);

  const { message } = useSelector((state) => state.auth);
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { showFeatureItems, primaryCardClass, primaryColorClass } = useSelector((state) => state.ThemeOptions);

  useEffect(() => {
    if (search === "?invoiceRedirect") {
      setInvoiceRedirect(true);
    } else {
      setInvoiceRedirect(false);
    }
    dispatch(signOutUser());
    localStorage.clear();
  }, []);
  const onChangeUsername = (e) => {
    const email = e.target.value;
    setUsername(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    // e.preventDefault();

    setLoading(true);

    dispatch(login(email, password, showFeatureItems))
      .then((response) => {
        // console.log('login page', response);
        if (response && response.id) {
          return dispatch(getUserInfo(response.id));
        } else {
          if (!invoiceRedirect) {
            setLoading(false);
          }
        }
        //window.location.reload();
      })
      .then((response) => {
        //const {vendorID } = useSelector((state) => state.auth);

        invoiceRedirect
          ? setTimeout(() => {
              setLoading(false);
              props.history.push("/SalesInvoices");
            }, 1000)
          : props.history.push("/Dashboard");
        //window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // const dsz_PDF_terms_file = () => {
  //   saveAs(DSZ_Supplier_Terms, "DSZ_Supplier_Terms_&_Conditions.pdf");
  // };
  // if(customValue){
  //   return <Redirect to="/MyLayout" />;
  // }
  let vDividerColor = 'divider-v  divider-v-md';
  if(primaryColorClass === 'dsz-green') vDividerColor = 'divider-green-v  divider-green-v-md';

  return (
    <>
      <div className="app-wrapper bg-white min-vh-100">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container>
                    <Row>
                      <Col
                        lg="6"
                        xs={{ span: 2, order: 2 }}
                        md={{ span: 2, order: 1 }}
                        className="d-flex align-items-center"
                      >
                        <div className={vDividerColor + " d-none d-lg-block"} />
                        <AvForm onValidSubmit={handleLogin} className="w-100">
                          <div className="w-100 pr-0 pr-lg-5">
                            <div className="text-black mt-3">
                              <span className="text-center">
                                <h1 className="display-4 mb-1 font-weight-bold">
                                  Login to your supplier account
                                </h1>
                                <p></p>
                              </span>

                              <div className="w-100">
                                <AvGroup>
                                  <Label for="email">Email</Label>
                                  <AvInput
                                    placeholder="Email"
                                    type="text"
                                    name="account"
                                    value={email}
                                    onChange={onChangeUsername}
                                    required
                                  />
                                  {/*  <AvFeedback>
                                    Please enter your email address!
                                  </AvFeedback> */}
                                </AvGroup>
                                <AvGroup>
                                  <div className="form-group mb-4">
                                    <div className="d-flex justify-content-between">
                                      <Label for="password">
                                        Enter your password
                                      </Label>
                                      {/* <a href="/recover">Forgot password?</a> */}
                                    </div>
                                    <AvInput
                                      placeholder="Enter your password"
                                      type="password"
                                      name="password"
                                      value={password}
                                      onChange={onChangePassword}
                                      required
                                    />
                                  </div>
                                </AvGroup>
                                <div className="pt-lg-4">
                                  <Button
                                    size="lg"
                                    className="btn-block text-uppercase font-weight-bold font-size-sm"
                                    color={primaryColorClass}
                                    disabled={loading}
                                  >
                                    {loading && (
                                      <span
                                        className="btn-wrapper--icon spinner-border spinner-border-sm mb-1 mr-2"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                    Login
                                  </Button>
                                </div>
                                {/* <div className="row justify-content-center">
                                  <Button
                                    size="sm"
                                    className="btn-pill text font-weight-bold font-size-sm mt-3"
                                    color="neutral-info"
                                    onClick={dsz_PDF_terms_file}
                                    title="Terms & Conditions"
                                  >
                                    {" "}
                                    {""}
                                    <FontAwesomeIcon
                                      icon={["fas", "file-pdf"]}
                                      className="font-size-sm"
                                    />
                                    <span className="p-1">
                                      {" "}
                                      Terms & Conditions
                                    </span>
                                  </Button>
                                </div> */}

                                {message && (
                                  <div className="form-group mt-3">
                                    <Alert
                                      className="alerts-alternate"
                                      color="danger"
                                    >
                                      <div className="d-flex align-items-center align-content-start">
                                        <span className="font-size-lg d-block d-40 mr-3 text-center bg-danger text-white rounded-sm">
                                          <FontAwesomeIcon
                                            icon={["far", "keyboard"]}
                                          />
                                        </span>
                                        <span>
                                          <strong className="d-block">
                                            Login Error!
                                          </strong>{" "}
                                          {message}
                                        </span>
                                      </div>
                                    </Alert>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </AvForm>
                      </Col>
                      <Col
                        lg="6"
                        xs={{ span: 3, order: 1 }}
                        md={{ span: 6, order: 2 }}
                        className="d-flex d-lg-flex align-items-center"
                      >
                        <div className="w-100 pb-4">
                          <img
                            alt="DROPSHIP ZONE"
                            className="mx-auto mb-5 mt-5 d-block img-fluid"
                            src={
                              primaryColorClass === "primary"
                                ? illustrationPurple
                                : illustration1
                            }
                          />

                          <div className="ml-5 mr-3 mt-5 pb-2 pt-5">
                            <a
                              href="https://www.dropshipzone.com.au/customer/account/supplier_register/"
                              target="_blank"
                            >
                              <Button
                                size="lg"
                                className="btn-block text-uppercase font-weight-bold font-size-sm"
                                color={primaryColorClass}
                              >
                                SIGN UP
                              </Button>
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
