import axios from './axios';
import qs from 'qs';

//1. refer to "Request Config" section on https://www.npmjs.com/package/axios for more request config options
//2. default response in JSON, but it can be changed through request config options

class RestClient {
	//simple get request from a url
	async get(url, config) {
		let options = {
			method: 'get',
			url,
			...config
		}

		return this.call(options);
	}

	//get request (query) with parameters
	async query(url, params, config) {
		let options = {
			method: 'get',
			url,
			params: params,
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			...config
		}

		return this.call(options);
	}

	//post JSON
	async post(url, data, config) {
		let options = {
			method: 'post',
			url,
			data,
			...config
		}

		return this.call(options);
	}

	//post form
	async postForm(url, data, config) {
		let options = {
			method: 'post',
			url,
			data: qs.stringify(data),
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			...config
		}

		return this.call(options);
	}

	async call(options) {
		let res = null, err = null, rawRes = null;
		try {
			let response = await axios(options);
			res = response.data;
			rawRes = response;
		} catch (error) {
			err = error;
		}

		return { res, err, rawRes };
	}
}

const instance = new RestClient();

export default instance;
