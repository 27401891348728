import { LISTEN_TO_CURRENT_USER_PROFILE } from './MyAccountConstants';


const initialState = {
    currentUserProfile: null
  };
  
  export default function MyAccountReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case LISTEN_TO_CURRENT_USER_PROFILE:
        return {
          ...state,
          currentUserProfile: payload.userProfile
        };
      
      default: {
        return state;
      }
    }
  }
  