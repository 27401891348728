
import RestClient  from '../utils/RestClient';



const AuthService = {
  async loginService (params){
    return  RestClient.post('/vendor_auth', params);
	},
  async userInfoService (params){
    return  RestClient.query('/api/admin/user/getUserInfo', params);
	}
}


export default AuthService;
