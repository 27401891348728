import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connect } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import SidebarUserbox from '../SidebarUserbox';

import { Collapse } from 'reactstrap';

import { INITIAL_SALES_ORDER_STATE } from '../../app/VendorSystem/Sales/Orders/SalesOrdersConstants';

import { INITIAL_MANAGE_PRODUCT_STATE } from '../../app/VendorSystem/Catalog/ManageProducts/ManageProductsConstants';

import { CLEAR_PRICE_REVIEW_RESPONSE } from '../../app/VendorSystem/Catalog/PriceReview/PriceReviewConstants';

import {
  Users,
  Award,
  UserCheck,
  ChevronRight,
  ChevronUp,
  Briefcase,
  Columns,
  Filter,
  DollarSign,
  Database,
  Edit,
  Sliders,
  Gift,
  Info,
  Home
} from 'react-feather';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox, showFeatureItems } = props;
  const dispatch = useDispatch();
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const toggleSidebarSalesOrder = () => {
    dispatch({ type: INITIAL_SALES_ORDER_STATE });
  };
  const toggleSidebarManageProduct = () => {
    dispatch({ type: INITIAL_MANAGE_PRODUCT_STATE });
  };

  const toggleSidebarPriceReview = () => {
    dispatch({ type: CLEAR_PRICE_REVIEW_RESPONSE });
  };

  const [productUpdateOpen, setProductUpdateOpen] = useState(false);
  const toggleProductUpdate = (event) => {
    setProductUpdateOpen(!productUpdateOpen);
    event.preventDefault();
  };
  const vendorID = useSelector((state) => state.auth.vendorID);
  const displayPromotionService = true;

  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox && <SidebarUserbox />}
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span></span>
          </div>
          <ul>
            <li>
              <NavLink
                to="/Dashboard"
                activeClassName="active"
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <Home />
                </span>
                Dashboard
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
          </ul>
          <div className="sidebar-header">
            <span>Sales</span>
          </div>
          <ul>
            <li>
              <NavLink
                onClick={toggleSidebarSalesOrder}
                to="/SalesOrders"
                activeClassName="active"
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <Award />
                </span>
                Orders
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>

            {/*  <li>
              <NavLink
                onClick={toggleSidebarSalesInvoices}
                to="/SalesInvoices"
                activeClassName="active"
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <Database />
                </span>
                Invoices
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li> */}

            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                to="/SalesUploadShipments"
                activeClassName="active"
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <Briefcase />
                </span>
                Upload Shipments
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
          </ul>
          {/* {displayInvoices ? ( */}
          <>
            <div className="sidebar-header">
              <span>Invoice</span>
            </div>
            <ul>
              <li>
                <NavLink
                  onClick={toggleSidebarMobile}
                  to="/SalesInvoices"
                  activeClassName="active"
                  className="nav-link-simple">
                  <span className="sidebar-icon">
                    <Database />
                  </span>
                  Invoice History
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRight />
                  </span>
                </NavLink>
              </li>
            </ul>
          </>
          {/* ) : null} */}


            {displayPromotionService ? (
              <>
                <div className="sidebar-header">
              <span>Marketing</span>
            </div>
            <ul>
              <li>
                <NavLink
                  onClick={toggleSidebarMobile}
                  to="/PromotionService"
                  activeClassName="active"
                  className="nav-link-simple">
                  <span className="sidebar-icon">
                    <Gift />
                  </span>
                  Product Promotion
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRight />
                  </span>
                </NavLink>
              </li>
            </ul>
              </>
            ) : null}


          <div className="sidebar-header">
            <span>Catalog</span>
          </div>
          <ul>
            <li>
              <NavLink
                onClick={toggleSidebarManageProduct}
                to="/ManageProducts"
                activeClassName="active"
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <Filter />
                </span>
                Manage Products
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            {!showFeatureItems && (
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                to="/ImportProducts"
                activeClassName="active"
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <Columns />
                </span>
                Import Products
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            )}


            {showFeatureItems && (
              <>
            <div className="sidebar-header">
              <span>Bulk Imports</span>
            </div>
            
              <ul>
               <li>
               <NavLink
                 onClick={toggleSidebarMobile}
                 to="/NewProducts"
                 activeClassName="active"
                 className="nav-link-simple">
                 <span className="sidebar-icon">
                   <Edit />
                 </span>
                 New Products
                 <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                   <ChevronRight />
                 </span>
               </NavLink>
             </li>
             <li>
               <a
                 //href="#/"
                 //onClick={toggleProductUpdate}
                 className={clsx({ active: productUpdateOpen })}>
                 <span className="sidebar-icon">
                   <Sliders />
                 </span>
                 <span className="sidebar-item-label">Existing Products</span>
                 <span className="sidebar-icon-indicator">
                   <ChevronUp />
                 </span>
               </a>
               <Collapse isOpen={true}>
                 <ul>
                   <li>
                     <NavLink
                       //onClick={toggleSidebarMobile}
                       to="/ProductInfoUpdate"
                       >
                       Info Update
                     </NavLink>
                   </li>
                   <li>
                     <NavLink
                      activeClassName="active"
                       onClick={toggleSidebarPriceReview}
                       to="/PriceReview">
                       Price / Shipping Update
                     </NavLink>
                   </li>
 
                 </ul>
               </Collapse>
             </li>
              </ul>
              </>
            )}
           

            {/* {showFeatureItems && (

            
              <li>
                <NavLink
                  onClick={toggleSidebarPriceReview}
                  to="/PriceReview"
                  activeClassName="active"
                  className="nav-link-simple">
                  <span className="sidebar-icon">
                    <Edit />
                  </span>
                  Price Review
                  <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                    <ChevronRight />
                  </span>
                </NavLink>
              </li>
            )} */}


          </ul>


          <div className="sidebar-header">
            <span>Profile Options</span>
          </div>
          <ul>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                to="/MyAccount"
                activeClassName="active"
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <Users />
                </span>
                My Account
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                to="/Login"
                activeClassName="active"
                className="nav-link-simple">
                <span className="sidebar-icon">
                  <UserCheck />
                </span>
                Logout
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  showFeatureItems: state.ThemeOptions.showFeatureItems
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
