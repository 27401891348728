import {
    IMPORT_PRODUCT_RESPONSE,
    CLEAR_IMPORT_PRODUCT_RESPONSE 
  } from './ProductInfoUpdateConstants';
  
  const initialState = {
    importResponse: '',
    importResponseMessage: '',
    isProductSubmitted: false
   
  };
  
  
  export default function ProductInfoUpdateReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
     
      case IMPORT_PRODUCT_RESPONSE:
        return {
          ...state,
          importResponse: payload.importRes,
          importResponseMessage: payload.importResMessage,
          isProductSubmitted: true
        };
        case CLEAR_IMPORT_PRODUCT_RESPONSE:
          return {
            ...state,
            importResponse: '',
            importResponseMessage: '',
            isProductSubmitted: false
          };
     
      default:
        return state;
    }
  }
  
  