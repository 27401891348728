import {connect} from "react-redux";
import React from "react";
import {Col, Row} from "reactstrap";

const PlanningPageTitle = ({
							   key,
							   className,
							   pageTitle,
							   pageIcon,
							   sense,
							   buttons
						   }) => {

	return <Row
			className={`app-page-title 
			 align-items-lg-start
			 app-page-title--sticky ${sense ? 'app-page-title-alt-sense' : ''} ${className ?? ''}`}>
			<Col className="app-page-title--heading" sm={'auto'}>
				<Row>

					<Col sm={"auto"}
						 className={"app-page-title--iconbox "}>
						{pageIcon}
					</Col>
					<Col className="app-page-title">
						<h1>{pageTitle}</h1>
					</Col>
				</Row>
			</Col>
			<Col>
				<div className={`app-page-title-buttons`}>
					{buttons}
				</div>
			</Col>
		</Row>
}


export default connect()(PlanningPageTitle);
