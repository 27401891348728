import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import ThemeOptions from './ThemeOptions';
import asyncReducer from './asyncReducer';
import authReducer from '../app/auth/authReducer';
import salesInvoicesReducer from '../app/VendorSystem/Sales/Invoices/SalesInvoicesReducer';
import manageProductReducer from '../app/VendorSystem/Catalog/ManageProducts/ManageProductsReducer';
import salesOrdersReducer from '../app/VendorSystem/Sales/Orders/SalesOrdersReducer';
import salesUploadShipmentsReducer from '../app/VendorSystem/Sales/UploadShipments/SalesUploadShipmentsReducer';
import dashboardReducer from '../app/VendorSystem/Dashboard/DashboardReducer';
import importProductsReducer from '../app/VendorSystem/Catalog/ImportProducts/ImportProductsReducer';
import newProductsLaunchReducer from '../app/VendorSystem/Catalog/NewProductsLaunch/NewProductsLaunchReducer';
import productInfoUpdateReducer from '../app/VendorSystem/Catalog/ProductInfoUpdate/ProductInfoUpdateReducer';
import myAccountReducer from '../app/VendorSystem/MyAccount/MyAccountReducer';
import priceReviewReducer from '../app/VendorSystem/Catalog/PriceReview/PriceReviewReducer';
import promotionServiceReducer from '../app/VendorSystem/Catalog/PromotionService/PromotionServiceReducer';


const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		ThemeOptions: ThemeOptions,
		async: asyncReducer,
		auth: authReducer,
		ManageProducts: manageProductReducer,
		salesInvoices: salesInvoicesReducer,
		salesOrders: salesOrdersReducer,
		salesUploadShipments: salesUploadShipmentsReducer,
		importProducts: importProductsReducer,
		newProducts: newProductsLaunchReducer,
		productInfoUpdate: productInfoUpdateReducer,
		dashboard: dashboardReducer,
		myaccount: myAccountReducer,
		priceReview: priceReviewReducer,
		promotionService: promotionServiceReducer
	});
	

export default rootReducer;
