/**
 * author: jialin
 * date: 2020/04/12
 * desc: requests
 */
import axios from "./axios";
import axiosProvider from "./axiosProvider";
import qs from "qs";

export const MethodType = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

const request = {
  remoteUrl: async (api = "", params = {}, config = {}) => {
    let method = config.method ? config.method : MethodType.POST;
    //const data = method === MethodType.GET ? 'params' : 'data';
    return axiosProvider(config)({
      url: api,
      method: method,
      data: params,
      config: config,
    })
      .then((response) => {
        return { res: response.data, err: null };
      })
      .catch((error) => {
        return { res: null, err: error };
      });
  },
  remotePostUrl: async (api = "", params = {}, config = {}) => {
    let method = config.method ? config.method : MethodType.POST;
    //const data = method === MethodType.GET ? 'params' : 'data';
    return axiosProvider(config)({
      url: api,
      method: method,
      data: params,
      config: config,
    })
      .then((response) => {
        return { res: response.data, err: null };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  remoteGetUrl: async (api = "", params = {}, config = {}) => {
    //const data = method === MethodType.GET ? 'params' : 'data';
    return axiosProvider(config)({
      url: api,
      method: "GET",
      params: params,
      paramsSerializer: (params) => qs.stringify(params),
      config: config,
    })
      .then((response) => {
        return { res: response.data, err: null };
      })
      .catch((error) => {
        return { res: null, err: error };
      });
  },

  remoteConfigUrl: async (config = {}) => {
    // config.transformRequest = [(data, header) => { addTokenToRequest(header); return data; }];
    return axiosProvider(config)
      .then((response) => {
        return { res: response.data, err: null };
      })
      .catch((error) => {
        return { res: null, err: error };
      });
  },
};

export const getCat = {
  remoteUrl: async (getCategoryURL) => {
    axios
      .get("/category", {
        headers: {
          accept: "application/json",
          Authorization: `jwt ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        data: {},
      })
      .then((res) => {
        console.log("getCategoryURL", res.data);
      })
      .catch((error) => {
        console.error(error);
        console.log("getCategoryURL error", error);
      });
  },
};

export const purchaseRequest = {
  remoteUrl: async (api = "", params = {}, config = {}) => {
    let method = config.method ? config.method : MethodType.POST;
    const data = method === MethodType.GET ? "params" : "data";
    return axios({
      url: api,
      method: method,
      [data]: params,
      config: config,
    })
      .then((response) => {
        return { res: response.data, err: null };
      })
      .catch((error) => {
        return { res: null, err: error };
      });
  },

  remoteConfigUrl: async (config = {}) => {
    // config.transformRequest = [(data, header) => { addTokenToRequest(header); return data; }];
    return axios(config)
      .then((response) => {
        return { res: response.data, err: null };
      })
      .catch((error) => {
        return { res: null, err: error };
      });
  },
  remoteConfigExportUrl: async (config = {}) => {
    // config.transformRequest = [(data, header) => { addTokenToRequest(header); return JSON.stringify(data); }];
    return axios(config)
      .then((response) => {
        return { res: response, err: null };
      })
      .catch((error) => {
        return { res: null, err: error };
      });
  },
};

export default request;
