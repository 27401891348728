import {
  SIGN_IN_USER,
  SIGN_OUT_USER,
  LOGIN_FAIL,
  SET_MESSAGE,
  CLEAR_MESSAGE
} from './authConstants';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  authenticated: false,
  currentUser: null,
  message: '',
  vendorID: ''
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN_USER:
      return {
        ...state,
        authenticated: true,
        currentUser: payload.currentUser,
        vendorID: payload.vendorID
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null
      };
      case LOGIN_FAIL:
        return {
          ...state,
          authenticated: false,
          currentUser: null
        };
    case SET_MESSAGE:
      return { 
        ...state,
        message: payload };

    case CLEAR_MESSAGE:
      return { message: '' };
    default:
      return state;
  }
}
