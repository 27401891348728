import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import { User } from 'react-feather';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { signOutUser } from '../../app/auth/authActions';

export function HeaderUserbox() {
  const dispatch = useDispatch();
  const { shopname,email } = useSelector((state) => state.auth);
  const { currentThemeColor, primaryColorClass } = useSelector((state) => state.ThemeOptions);
  //const {currentUserProfile} = useSelector(state => state.profile);
  const history = useHistory();

  function handleSignOut(e) {
    e.preventDefault();
    try {
      dispatch(signOutUser());
      history.push('/Login');
    } catch (error) {
      toast.error(error.message);
    }
  }
  let menuCssClass = 'dropdown-menu overflow-hidden p-0';
  if(currentThemeColor === 'green') menuCssClass = 'dropdown-menu-green overflow-hidden p-0';
//console.log("currentUser",currentUser)
  return (
    <>
      <UncontrolledDropdown className="position-relative ml-2">
        <DropdownToggle
          color="link"
          className="p-0 text-left d-flex btn-transition-none align-items-center">
          
            <div
              color="neutral-primary"
              className="bg-neutral-primary text-primary font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                <Badge color="primary" className="badge-circle">
                  Online
                </Badge>
                <User />
              </span>
            </div>
          
          <div className="d-none d-xl-block pl-2">
            {shopname && (
            <>
            <div className="font-weight-bold">{shopname}</div>
            <span className="text-black-50">{email}</span>
            </>
            )}
            
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon
              icon={['fas', 'angle-down']}
              className="opacity-5"
            />
          </span>
        </DropdownToggle>
        <DropdownMenu right className={menuCssClass}>
          <ListGroup flush className="text-left bg-transparent">
            <ListGroupItem className="rounded-top">
              <Nav pills className={"flex-column nav-neutral-" + primaryColorClass}>
                <NavItem className={"nav-header d-flex pt-1 pb-2 font-weight-bold align-items-center text-"+ primaryColorClass}>
                  <div>Profile options</div>
                  {/* <div className="ml-auto font-size-xs">
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      id="ChangeSettingsTooltip">
                      <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </a>
                    <UncontrolledTooltip
                      target="ChangeSettingsTooltip"
                      container="body">
                      Change settings
                    </UncontrolledTooltip>
                  </div> */}
                </NavItem>
                <NavItem>
                  <NavLinkStrap href="/MyAccount">
                    My Account
                  </NavLinkStrap>
                </NavItem>
                <NavItem>
                  <NavLinkStrap href="#/" onClick={(e) => handleSignOut(e)}>
                    Sign out
                  </NavLinkStrap>
                </NavItem>
              </Nav>
            </ListGroupItem>
          </ListGroup>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default HeaderUserbox;
