import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledPopover, Badge, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Bell } from 'react-feather';
import moment from 'moment-timezone';

const HeaderDots = () => {
  const { priceReviewPeriodStatus,priceReviewPeriodStart,
  priceReviewPeriodEnd,
  priceReviewPeriodEffective } = useSelector((state) => state.priceReview);


  const [enableDropDownOpen, setEnableDropDownOpen] = useState(false);
  const enableButtonToggle = () =>
    setEnableDropDownOpen((prevState) => !prevState);

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        {priceReviewPeriodStatus && (
          <span className="d-inline-block pr-2">
            <Button
              id="alertsPopover"
              color="neutral-success"
              className="bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                <Badge color="success" className="badge-circle">
                  Price Review Date Notification
                </Badge>
                <Bell />
              </span>
            </Button>
            <UncontrolledPopover
            isOpen={enableDropDownOpen}
            toggle={enableButtonToggle}
              target="alertsPopover"
              trigger="legacy"
              className="popover-custom-wrapper popover-custom-md"
              placement="auto">
              <div className="bg-composed-wrapper bg-happy-green mx-3 mt-3 border-0 text-center rounded-sm">
                <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                <div className="bg-composed-wrapper--content text-white px-2 py-4">
                  <h4 className="font-size-xl font-weight-bold mb-2">
                    Price Review Date Notification
                  </h4>
                </div>
              </div>
              <div className="mx-3 mt-3">
                The price review submission window is{' '}
                <Badge pill color="success" className="m-1">
                  open
                </Badge>
                . It closes on {' '}{moment.utc(priceReviewPeriodEnd).tz('Australia/Sydney').format('D MMMM, YYYY, hh:mm A z')}
              </div>

              <div className="text-center py-3">
                <NavLink to='/PriceReview'>
                <Button
                onClick={enableButtonToggle}
                  color="primary"
                  className="hover-scale-sm font-weight-bold btn-pill px-4 btn btn-primary">
                
                  <span className="btn-wrapper--label">Price Review</span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                  </span>
                </Button>
                </NavLink>
              </div>
            </UncontrolledPopover>
          </span>
        )}
        {!priceReviewPeriodStatus && (
          <span className="d-inline-block pr-2">
            <Button
              id="alertsPopoverPriceReviewClose"
              color="neutral-danger"
              className="bg-neutral-danger text-danger font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                <Badge color="danger" className="badge-circle">
                  Price Review Date Notification
                </Badge>
                <Bell />
              </span>
            </Button>
            <UncontrolledPopover
              target="alertsPopoverPriceReviewClose"
              trigger="legacy"
              className="popover-custom-wrapper popover-custom-md"
              placement="auto">
              <div className="bg-composed-wrapper bg-love-kiss mx-3 mt-3 border-0 text-center rounded-sm">
                <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                <div className="bg-composed-wrapper--content text-white px-2 py-4">
                  <h4 className="font-size-xl font-weight-bold mb-2">
                    Price Review Date Notification
                  </h4>
                </div>
              </div>
              <div className="mx-3 mt-3 mb-3">
                The price review submission window is{' '}
                <Badge pill color="danger" className="m-1">
                  closed
                </Badge>
                . It next opens on {' '}{moment.utc(priceReviewPeriodEffective).tz('Australia/Sydney').format('D MMMM, YYYY, hh:mm A z')}
              </div>
            </UncontrolledPopover>
          </span>
        )}
      </div>
    </>
  );
};

export default HeaderDots;
