import {
  GET_VENDOR_SKU,
  GET_VENDOR_SKU_CSV,
  CLEAR_VENDOR_SKU,
  SET_VENDOR_SKU_PAGE_SIZE,
  RETAIN_VENDOR_SKU_STATE,
  RETAIN_VENDOR_SKU_STATE_CSV,
  CLEAR_RETAIN_VENDOR_SKU_STATE,
  SET_SELECT_VENDOR_SKU,
  SET_VENDOR_SKU_SEARCH_FILTERS,
  SET_VENDOR_SKU_PAGE_NO,
  SET_CSV_PAGE_NO,
  GET_VENDOR_SKU_DETAIL,
  CLEAR_VENDOR_SKU_DETAIL,
  RETAIN_VENDOR_SKU_DETAIL_STATE,
  CLEAR_RETAIN_VENDOR_SKU_DETAIL_STATE,
  SET_PRODUCT_DETAIL_VALUES,
  SET_PRODUCT_PRICE_VALUES,
  CLEAR_PRODUCT_DETAIL_VALUES,
  CLEAR_PRODUCT_PRICE_VALUES,
  CREATE_VENDOR_PRODUCT_STATUS_RESPONSE,
  CLEAR_PRODUCT_STATUS_RESPONSE,
  CREATE_VENDOR_PRODUCT,
  SET_PRODUCT_IMAGE_VALUES,
  CLEAR_PRODUCT_IMAGE_VALUES,
  FETCH_PRODUCT_CATEGORIES,
  FETCH_PRODUCT_NEW_CATEGORIES,
  UPDATE_VENDOR_PRODUCT_STATUS_RESPONSE,
  CLEAR_UPDATE_PRODUCT_STATUS_RESPONSE,
  SET_PRODUCT_CATEGORY_VALUES,
  CLEAR_PRODUCT_CATEGORIES,
  INITIAL_MANAGE_PRODUCT_STATE,
  SET_PRODUCT_FILTER_VALUES,
  CLEAR_PRODUCT_FILTER_VALUES,
  GET_PRICE_REVIEW_SKU,
  GET_PRICE_UPDATE_SINGLE_RESPONSE,
} from "./ManageProductsConstants";

const initialState = {
  vendorSkuList: [],
  vendorSkuListCSV: [],
  categories: [],
  newCategories: [],
  selectedProductDetails: null,
  selectedProductPriceList: null,
  selectedProductImageList: [],
  vendorSkuDetailList: "",
  retainVendorSkuState: false,
  retainVendorSkuStateCSV: true,
  retainVendorSkuDetailState: false,
  searchFilters: "",
  createNewProductResponse: "",
  updateProductStatusResponse: "",
  totalCount: 0,
  totalPages: 0,
  pageSize: 50,
  totalCountCSV: 0,
  totalPagesCSV: 0,
  pageSizeCSV: 5000,
  currentPageCSV: 1,
  isCSVComplete: false,
  currentPage: 1,
  isProductSelectAll: false,
  products: [],
  filterValues: [],
  selectedProduct: [],
  isPriceReview: true,
  priceReviewSKU: [],
  priceReviewResponseMessage: "",
  priceReviewResponseData: {},
  priceReviewResponseTotal: 0,
  priceReviewResponseAbnormal: 0,
  priceReviewResponseFail: 0,
  isSelected: true,
  productFilterValues: {
    sku: "",
    parent_sku: "",
    start_product_id: "",
    end_product_id: "",
    status: "",
    approval: "",
    name: "",
    ean_code: "",
  },
};

export default function manageProductReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_VENDOR_SKU:
      return {
        ...state,
        vendorSkuList: [...state.vendorSkuList, ...payload.vendorSkuList],
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        retainVendorSkuDetailState: false,
        createNewProductResponse: "",
        priceReviewResponseMessage: "",
        priceReviewResponseData: {},
        priceReviewResponseTotal: 0,
        priceReviewResponseAbnormal: 0,
        retainVendorSkuState: true,
        priceReviewResponseFail: 0,
      };
    case GET_VENDOR_SKU_CSV:
      return {
        ...state,
        vendorSkuListCSV: [...state.vendorSkuList, ...payload.vendorSkuList],
        totalCountCSV: payload.totalCount,
        totalPagesCSV: payload.totalPages,
        currentPageCSV: payload.currentPage,
        retainVendorSkuStateCSV: true,
        isCSVComplete: true,
      };
    case FETCH_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, ...payload.categoriesList],
      };
    case FETCH_PRODUCT_NEW_CATEGORIES:
      return {
        ...state,
        newCategories: [...payload.categoriesList],
      };
    case GET_PRICE_REVIEW_SKU:
      return {
        ...state,
        priceReviewSKU: payload.priceReviewSKU,
      };
    case SET_PRODUCT_CATEGORY_VALUES:
      return {
        ...state,
        categories: payload,
      };
    case CLEAR_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: [],
      };
    case CLEAR_VENDOR_SKU:
      return {
        ...state,
        vendorSkuList: [],
        priceReviewSKU: [],
        // currentPage: 1
      };
    case GET_VENDOR_SKU_DETAIL:
      return {
        ...state,
        selectedProductDetails: payload.vendorSkuDetailList,
        //at the moment the selectedProductDetails are mutated; so we save a default value copy for comparation purpose; in future consider refactoring the code
        selectedProductDetailsDefaultValue: JSON.parse(
          JSON.stringify(payload.vendorSkuDetailList)
        ),
        selectedProductPriceList: payload.vendorSkuPriceList,
        selectedProductImageList: payload.dragArray,
        vendorSkuDetailList: payload.vendorSkuDetailList,
      };
    case CREATE_VENDOR_PRODUCT:
      return {
        ...state,
        selectedProductDetails: payload.detailList,
        selectedProductPriceList: payload.priceList,
        selectedProductImageList: createNewProductImageList(),
      };
    case CREATE_VENDOR_PRODUCT_STATUS_RESPONSE:
      return {
        ...state,
        createNewProductResponse: payload.createNewProductResponse,
        upVendorProductResponseMessage: payload.upVendorProductResponseMessage,
        retainVendorSkuDetailState: false,
        retainVendorSkuState: false,
      };
    case CLEAR_PRODUCT_STATUS_RESPONSE:
      return {
        ...state,
        createNewProductResponse: "",
        priceReviewResponseMessage: "",
        priceReviewResponseData: {},
        priceReviewResponseTotal: 0,
        priceReviewResponseAbnormal: 0,
        priceReviewResponseFail: 0,
      };
    case UPDATE_VENDOR_PRODUCT_STATUS_RESPONSE:
      return {
        ...state,
        updateProductStatusResponse: payload.updateProductStatusResponse,
        retainVendorSkuState: false,
      };
    case CLEAR_UPDATE_PRODUCT_STATUS_RESPONSE:
      return {
        ...state,
        updateProductStatusResponse: "",
      };
    case CLEAR_VENDOR_SKU_DETAIL:
      return {
        ...state,
        vendorSkuDetailList: [],
      };
    case RETAIN_VENDOR_SKU_DETAIL_STATE:
      return {
        ...state,
        retainVendorSkuDetailState: true,
      };
    case CLEAR_RETAIN_VENDOR_SKU_DETAIL_STATE:
      return {
        ...state,
        retainVendorSkuDetailState: false,
        selectedProductDetails: null,
        selectedProductPriceList: null,
        selectedProductImageList: [],
      };
    case SET_PRODUCT_DETAIL_VALUES:
      return {
        ...state,
        selectedProductDetails: payload,
        retainVendorSkuDetailState: true,
      };
    case SET_PRODUCT_PRICE_VALUES:
      return {
        ...state,
        selectedProductPriceList: payload,
        retainVendorSkuDetailState: true,
      };
    case SET_PRODUCT_IMAGE_VALUES:
      return {
        ...state,
        selectedProductImageList: payload,
        retainVendorSkuDetailState: true,
      };
    case CLEAR_PRODUCT_DETAIL_VALUES:
      return {
        ...state,
        selectedProductDetails: null,
      };
    case CLEAR_PRODUCT_PRICE_VALUES:
      return {
        ...state,
        selectedProductPriceList: null,
      };
    case CLEAR_PRODUCT_IMAGE_VALUES:
      return {
        ...state,
        selectedProductImageList: [],
      };
    case SET_VENDOR_SKU_PAGE_SIZE:
      return {
        ...state,
        retainVendorSkuState: false,
        pageSize: payload,
      };
    case SET_VENDOR_SKU_PAGE_NO:
      return {
        ...state,
        retainVendorSkuState: false,
        currentPage: payload,
      };
    case SET_CSV_PAGE_NO:
      return {
        ...state,
        retainVendorSkuState: false,
        currentPageCSV: payload,
        retainVendorSkuStateCSV: false,
      };
    case RETAIN_VENDOR_SKU_STATE:
      return {
        ...state,
        retainVendorSkuState: true,
      };
    case RETAIN_VENDOR_SKU_STATE_CSV:
      return {
        ...state,
        retainVendorSkuStateCSV: true,
      };
    case CLEAR_RETAIN_VENDOR_SKU_STATE:
      return {
        ...state,
        retainVendorSkuState: false,
      };
    case SET_VENDOR_SKU_SEARCH_FILTERS:
      return {
        ...state,
        retainVendorSkuState: false,
        searchFilters: payload,
        currentPage: 1,
      };
    case SET_SELECT_VENDOR_SKU:
      return {
        ...state,
        vendorSkuList: setVendorSkuSelection(
          state.vendorSkuList,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll
        ),
        selectedProduct: selectedProducts(
          payload.vendorID,
          state.vendorSkuList
        ),
        isProductSelectAll: payload.isSelectAll,
      };
    case SET_PRODUCT_FILTER_VALUES:
      return {
        ...state,
        productFilterValues: payload,
        retainVendorSkuState: true,
      };
    case CLEAR_PRODUCT_FILTER_VALUES:
      return {
        ...state,
        productFilterValues: null,
      };
    case GET_PRICE_UPDATE_SINGLE_RESPONSE:
      let storedPriceReviewSKURecords = state.priceReviewSKU;
      const newUpdatedSKUPriceDetails = payload.priceReviewResponseData;
      if (newUpdatedSKUPriceDetails.status !== "failed") {
        let matched = storedPriceReviewSKURecords.find(
          (s) => s.sku === newUpdatedSKUPriceDetails.sku
        );
        if (matched) {
          if (newUpdatedSKUPriceDetails.new_price) {
            matched.new_price = newUpdatedSKUPriceDetails.new_price;
          }
          if (newUpdatedSKUPriceDetails.new_rrp) {
            matched.new_rrp = newUpdatedSKUPriceDetails.new_rrp;
          }
          if (
            newUpdatedSKUPriceDetails.new_zone_rates &&
            newUpdatedSKUPriceDetails.new_zone_rates.data
          ) {
            matched.new_zone_rates_json =
              newUpdatedSKUPriceDetails.new_zone_rates;
          }
        } else {
          storedPriceReviewSKURecords.push({
            sku: newUpdatedSKUPriceDetails.sku,
            new_price: newUpdatedSKUPriceDetails.new_price,
            new_rrp: newUpdatedSKUPriceDetails.new_rrp,
            new_zone_rates_json: newUpdatedSKUPriceDetails.new_zone_rates,
          });
        }
      }
      return {
        ...state,
        priceReviewResponseMessage: payload.priceReviewResponseMessage,
        priceReviewResponseData: newUpdatedSKUPriceDetails,
        priceReviewResponseTotal: payload.priceReviewResponseTotal,
        priceReviewResponseAbnormal: payload.priceReviewResponseAbnormal,
        priceReviewResponseFail: payload.priceReviewResponseFail,
      };
    case INITIAL_MANAGE_PRODUCT_STATE:
      return {
        ...state,
        vendorSkuList: [],
        categories: [],
        selectedProductDetails: null,
        selectedProductPriceList: null,
        selectedProductImageList: [],
        vendorSkuDetailList: "",
        retainVendorSkuState: false,
        retainVendorSkuDetailState: false,
        searchFilters: "",
        createNewProductResponse: "",
        updateProductStatusResponse: "",
        totalCount: 0,
        totalPages: 0,
        pageSize: 50,
        currentPage: 1,
        isProductSelectAll: false,
        products: [],
        filterValues: [],
        selectedProduct: [],
        priceReviewSKU: [],
        priceReviewResponseMessage: "",
        priceReviewResponseData: {},
        priceReviewResponseTotal: 0,
        priceReviewResponseAbnormal: 0,
        priceReviewResponseFail: 0,
        isSelected: true,
        productFilterValues: {
          sku: "",
          start_product_id: "",
          end_product_id: "",
          status: "",
          approval: "",
          name: "",
          ean_code: "",
        },
      };
    default:
      return state;
  }
}

const createNewProductImageList = () => {
  const container = [];

  return container;
};

const setVendorSkuSelection = (
  productList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  const newList = [];
  console.log(
    "setVendorSkuSelection",
    productList,
    productID,
    isSelectAllMode,
    isSelectAll
  );
  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      product.isSelected = isSelectAll;
      newList.push(product);
    });
  } else {
    productList.forEach((product, index) => {
      if (product.sku === productID) {
        product.isSelected = !product.isSelected;
      }
      newList.push(product);
    });
  }
  return newList;
};

// const selectedProducts = (selectProductList) => {
//   const selectedList = selectProductList.filter((order) => order.isSelected);
//   return selectedList;
// };

const selectedProducts = (vendorID, selectProductList) => {
  const selectedList = selectProductList.filter((order) => order.isSelected);

  const mArray = [];
  if (selectedList && selectedList.length !== 0)
    selectedList.forEach((obj) => {
      let container = {};

      container.sku = obj.sku;
      container.name = obj.name;
      container.ean_code = obj.ean_code;
      container.description = obj.description.replace(/"/g, '""');
      container.status = obj.status;
      container.brand_name = obj.brand_name;
      container.colour = obj.colour;
      container.weight = obj.weight;
      container.length = obj.length;
      container.width = obj.width;
      container.height = obj.height;
      container.cbm = obj.cbm;
      container.price = obj.price;
      container.rrp = obj.rrp;
      container.special_price = obj.special_price;
      container.special_from_date = obj.special_from_date;
      container.special_to_date = obj.special_to_date;
      // container.meta_title = obj.meta_title;
      // container.meta_keywords = obj.meta_keywords;
      // container.meta_description = obj.meta_description;
      container.stock = obj.stock;
      container.min_qty = obj.min_qty;
      container.categories = obj.categories;

      container.parent_sku = obj.parent_sku;
      container.parent_sku_name = obj.parent_sku_name;
      container.option1_name = obj.option1_name;
      container.option1_value = obj.option1_value;
      container.option2_name = obj.option2_name;
      container.option2_value = obj.option2_value;

      if (obj.zone_rates) {
        const jsonObj = obj.zone_rates;
        try {
          var jsonRates = jsonObj;
        } catch (e) {
          console.log("JsonRates Reducer Error", e);
        }

        // console.log(jsonRates)
        container.act = jsonRates.act;
        container.nsw_m = jsonRates.nsw_m;
        container.nsw_r = jsonRates.nsw_r;
        container.nt_m = jsonRates.nt_m;
        container.nt_r = jsonRates.nt_r;
        container.qld_m = jsonRates.qld_m;
        container.qld_r = jsonRates.qld_r;
        container.remote = jsonRates.remote;
        container.sa_m = jsonRates.sa_m;
        container.sa_r = jsonRates.sa_r;
        container.tas_m = jsonRates.tas_m;
        container.tas_r = jsonRates.tas_r;
        container.vic_m = jsonRates.vic_m;
        container.vic_r = jsonRates.vic_r;
        container.wa_m = jsonRates.wa_m;
        container.wa_r = jsonRates.wa_r;
        // if vendorID = 1 add export column "Zone Rates NZ"
        if (vendorID == 1) {
          container.nz = jsonRates.nz ? jsonRates.nz : 9999;
        }
      }

      if (obj.images)
        try {
          container.images = obj.images;
        } catch (e) {
          console.log(e);
        }

      mArray.push(container);
    });

  // console.log("Array", mArray);
  return mArray;
};
