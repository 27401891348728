export const FETCH_VENDOR_ORDERS = 'FETCH_VENDOR_ORDERS';
export const CLEAR_VENDOR_ORDERS = 'CLEAR_VENDOR_ORDERS';
export const RETAIN_VENDOR_ORDER_STATE = 'RETAIN_VENDOR_ORDER_STATE';
export const CLEAR_VENDOR_ORDER_RETAIN_STATE = ' CLEAR_VENDOR_ORDER_RETAIN_STATE';
export const SET_VENDOR_ORDERS_PAGE_SIZE = 'SET_VENDOR_ORDERS_PAGE_SIZE';
export const SET_VENDOR_ORDERS_PAGE_NO = 'SET_VENDOR_ORDERS_PAGE_NO';
export const SET_VENDOR_ORDERS_SEARCH_KEYWORDS = 'SET_VENDOR_ORDERS_SEARCH_KEYWORDS';
export const SET_SELECT_VENDOR_ORDER = 'SET_SELECT_VENDOR_ORDER';
export const SET_VENDOR_ORDER_SEARCH_FILTERS = ' SET_VENDOR_ORDER_SEARCH_FILTERS';
export const CLEAR_SELECTED_ORDER_LIST = 'CLEAR_SELECTED_ORDER_LIST';

export const FETCH_VENDOR_ORDERS_DETAIL = 'FETCH_VENDOR_ORDERS_DETAIL';
export const CLEAR_VENDOR_ORDERS_DETAIL = 'CLEAR_VENDOR_ORDERS_DETAIL';
export const RETAIN_VENDOR_ORDERS_DETAIL_STATE = 'RETAIN_VENDOR_ORDERS_DETAIL_STATE';
export const SET_RETAIN_VENDOR_ORDERS_DETAIL_STATE = 'SET_RETAIN_VENDOR_ORDERS_DETAIL_STATE';

export const UPDATE_VENDOR_ORDERS_DETAIL = 'UPDATE_VENDOR_ORDERS_DETAIL';
export const UPDATE_ORDER_EXPORT_STATUS = 'UPDATE_ORDER_EXPORT_STATUS';
export const CLEAR_STATUS_UPDATE_RESPONSE = 'CLEAR_STATUS_UPDATE_RESPONSE ';

export const GET_SHIPMENT = 'GET_SHIPMENT';
export const RETAIN_SHIPMENT_STATE = ' RETAIN_SHIPMENT_STATE';
export const SET_RETAIN_SHIPMENT_STATE = 'SET_RETAIN_SHIPMENT_STATE';
export const CLEAR_SHIPMENT_LIST = 'CLEAR_SHIPMENT_LIST';

export const INITIAL_SALES_ORDER_STATE = 'INITIAL_SALES_ORDER_STATE';
export const FETCH_VENDOR_EXPORT_ORDERS ='FETCH_VENDOR_EXPORT_ORDERS';

export const CREATE_ORDER_SHIPMENT = 'CREATE_ORDER_SHIPMENT';
export const CLEAR_SHIPMENT_RESPONSE = 'CLEAR_SHIPMENT_RESPONSE';

export const REMOVE_ORDER_SHIPMENT = 'REMOVE_ORDER_SHIPMENT';
export const GET_COURIER_LIST = 'GET_COURIER_LIST';

export const SET_ORDER_FILTER_VALUES = 'SET_ORDER_FILTER_VALUES';
export const CLEAR_ORDER_FILTER_VALUES = 'CLEAR_ORDER_FILTER_VALUES';