import {
  SIGN_IN_USER,
  SIGN_OUT_USER,
  LOGIN_FAIL,
  SET_MESSAGE,
  CLEAR_MESSAGE
} from './authConstants';

import { CLEAR_SALES_FIGURES } from '../VendorSystem/Dashboard/DashboardConstants';

import { APP_LOADED } from '../../reducers/asyncReducer';
import AuthService from '../../../src/services/auth.service';

import { VendorServiceObj } from '../VendorSystem/service/api.service';

import {
  fetchSalesFigures,
  fetchGraphOrderData
} from '../VendorSystem/Dashboard/DashboardActions';
//Call price review notification
import {
  priceReviewPeriod
} from '../VendorSystem/Catalog/PriceReview/PriceReviewActions';

import { setFeatureFlag } from '../../reducers/ThemeOptions';

//import request to call API
import moment from 'moment-timezone';
export function signInUser(data, email, account) {
  //console.log("SIGN_IN_USER 1");
  return {
    type: SIGN_IN_USER,
    payload: { data, email, account }
  };
}

// decodes a base-64 encoded string to get token expire info.
const decode = (payload) => {
  return JSON.parse(atob(payload));
};
const payload = (token) => {
  const payload = token.split('.')[1];
  return decode(payload);
};
// store exp and refresh time into local storage && add userInfo into sessionReducer.
const processToken = (tokenPayload) => {
  const expiresAtDate = new Date(Number(tokenPayload['exp']));
  // const activeAtDate  = new Date(Number(tokenPayload['iat']));
  const expiresAt = moment(expiresAtDate).tz('Australia/Melbourne');
  // const activeAt = moment(activeAtDate).tz('Australia/Melbourne');
  // localStorage.setItem('refresh_expires_at', JSON.stringify(activeAt.valueOf()));
  localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  // const channelArray = window.$utils.setUserChannel(tokenPayload);
  // const userRole = window.$utils.setUserRole(tokenPayload);
  const userInfo = {
    id: tokenPayload && tokenPayload.id ? tokenPayload.id : null,
    account:
      tokenPayload && tokenPayload.realAccount ? tokenPayload.realAccount : '',
    expiresAt: expiresAt
  };
  return userInfo;
};

const getAuthToketn = (loginResponse) => (dispatch) => {
  let token;
  if (
    loginResponse &&
    loginResponse.user &&
    loginResponse.token &&
    loginResponse.token.token
  ) {
    token = loginResponse.token.token;
  } else {
    token = localStorage.getItem('token');
  }
  //console.log('getAuthToketn - currentUser.vendor_id :', currentUser.vendor_id);
  if (token) {
    window.localStorage.clear();
    sessionStorage.clear();

    localStorage.setItem('token', token);
    localStorage.setItem('vednorid', loginResponse.user.vendor_id);
    //sessionStorage.setItem('currentuser', JSON.stringify(loginResponse.user));
    localStorage.setItem('currentuser', JSON.stringify(loginResponse.user));
    localStorage.setItem('f_mode', false);

    //Call Dashboard
    dispatch(fetchSalesFigures(loginResponse.user.vendor_id));
    dispatch(fetchGraphOrderData(loginResponse.user.vendor_id, '24h', '1'));
    //Call price review
    //dispatch(priceReviewPeriod(loginResponse.user.vendor_id));
    return;
  }
  //   return VendorServiceObj.authLogin(loginObj).then(
  //     (response) => {
  //       // console.log('Call Auth Login Action Method Response',response);
  //       if (response.res && response.res.token) {
  //         window.localStorage.clear();
  //         sessionStorage.clear();

  //         localStorage.setItem('token', response.res.token);
  //         localStorage.setItem('vednorid', currentUser.vendor_id);
  //         const tokenPayload = payload(response.res.token);
  //         const userInfoObj = processToken(tokenPayload);

  //         sessionStorage.setItem('currentuser', JSON.stringify(currentUser));
  //         //Call Dashboard
  //         dispatch(fetchSalesFigures(currentUser.vendor_id));
  //         dispatch(fetchGraphOrderData(currentUser.vendor_id, '24h','1'));

  //         return Promise.resolve(userInfoObj);
  //       } else {
  //         const msg =
  //           response.err &&
  //           response.err.response &&
  //           response.err.response.data &&
  //           response.err.response.data.error
  //             ? response.err.response.data.error
  //             : 'Get User Info Fail';

  //         dispatch({
  //           type: SET_MESSAGE,
  //           payload: msg
  //         });
  //         return Promise.reject(msg);
  //       }
  //     },
  //     (error) => {
  //       const message =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();

  //       dispatch({
  //         type: SET_MESSAGE,
  //         payload: message
  //       });

  //       return Promise.reject(message);
  //     }
  //   );
};

export const getUserInfo = (userId) => (dispatch) => {
  const userObj = { userId: userId };
  return AuthService.userInfoService(userObj).then(
    (response) => {
      if (response.res && response.res) {
        const userInfoReceived = response.res;
        dispatch(signInUser(userInfoReceived, '', userInfoReceived.account));
        return Promise.resolve(userInfoReceived);
      } else {
        const msg =
          response.err &&
          response.err.response &&
          response.err.response.data &&
          response.err.response.data.error
            ? response.err.response.data.error
            : 'Get User Info Fail';

        dispatch({
          type: SET_MESSAGE,
          payload: msg
        });
        return Promise.reject(msg);
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message
      });

      return Promise.reject(message);
    }
  );
};

export const login = (username, password, showFeatureItems) => (dispatch) => {
  let params = {};
  params['email'] = username;
  params['password'] = password;

  const loginObj = JSON.stringify(params);
  //console.log('Call Login Action Method',loginObj);
  return VendorServiceObj.userLogin(loginObj).then(
    (response) => {
     // console.log('Call Login Action Method Response', response);

      if (response.res) {
        const loginResponse = response.res.data;
        dispatch(getAuthToketn(loginResponse));
        const currentUser = loginResponse.user;
        const vendorID = loginResponse.user.vendor_id;
        // console.log("SIGN_IN_USER 2");
        if (showFeatureItems) dispatch(priceReviewPeriod(vendorID));

        dispatch({
          type: SIGN_IN_USER,
          payload: { currentUser, vendorID }
        });
        dispatch({ type: APP_LOADED });

        return Promise.resolve();
        //load setting
        //dispatch(fetchSettings());
      } else {
        const msg =
          response.err &&
          response.err.response &&
          response.err.response.data &&
          response.err.response.data.error
            ? response.err.response.data.error
            : 'invalid username or password';
        dispatch({
          type: LOGIN_FAIL
        });

        dispatch({
          type: SET_MESSAGE,
          payload: msg
        });
        return Promise.reject();
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message
      });

      return Promise.reject(message);
    }
  );
};

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  payload: message
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE
});

export function signOutUser() {
  return function (dispatch) {
    window.localStorage.clear();
    sessionStorage.clear();
    dispatch({
      type: CLEAR_SALES_FIGURES
    });
    dispatch({
      type: SIGN_OUT_USER
    });
    
  };
}

export function verifyAuth() {
  return function (dispatch) {
    const token = localStorage.getItem('token');
    const vendorID = localStorage.getItem('vednorid');
    //const currentUser = JSON.parse(sessionStorage.getItem('currentuser'));
    const currentUser = JSON.parse(localStorage.getItem('currentuser'));
    const fmode = localStorage.getItem('f_mode');

    //console.log('currentUser - token >> fmode :', fmode);
    if (token && currentUser && vendorID) {
      //console.log("SIGN_IN_USER 3",fmode);
      dispatch({
        type: SIGN_IN_USER,
        payload: { currentUser, vendorID }
      });

      //Call Dashboard
      dispatch(fetchSalesFigures(vendorID));
      dispatch(fetchGraphOrderData(vendorID, '24h', '1'));
      if (fmode) dispatch(priceReviewPeriod(vendorID));
     
    } else {
      dispatch(signOutUser());
      // todo without token redirect:   history.push('/auth/login')
    }
  };
}
