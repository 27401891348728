import {
    GET_PROMOTION_EVENTS,
    CLEAR_PROMOTION_EVENTS,
    RETAIN_PROMOTION_EVENTS,
    SET_PROMOTION_EVENTS_PAGE_SIZE,
    SET_PROMOTION_EVENTS_PAGE_NO,
    CLEAR_PROMOTION_RETAIN_STATE,
    GET_PROMOTION_EVENT_FOR_SUBMISSION,
    GET_PROMOTION_EVENT_HISTORY,
    UPLOAD_PROMOTION_FILE_RESPONSE,
    CLEAR_PROMOTION_FILE_RESPONSE
} from './PromotionServiceConstants';

const initialState = {
    promotionEventsList: [],
    eventForSubmissionDetails: '',
    eventHistoryDetails: '',
    retainState: false,
    totalCount: 0,
    totalPages: 0,
    pageSize: 100,
    currentPage: 1,

    importResponse: '',
    importResponseMessage: '',
    isFileSubmitted: false,
    importResponseData: [],
    importResponseTotal: 0,
    importResponseSuccess: 0,
    importResponseFail: 0
}


export default function promotionServiceReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case GET_PROMOTION_EVENTS:
            return {
                ...state,
                promotionEventsList: [...state.promotionEventsList, ...payload.promotionEventsList],
                totalCount: payload.totalCount,
                totalPages: payload.totalPages,
                currentPage: payload.currentPage,
                retainState: true,
                eventHistoryDetails: '',
                eventForSubmissionDetails: '',
                importResponse: '',
                importResponseMessage: '',
                isFileSubmitted: false,
                importResponseData: [],
                importResponseTotal: 0,
                importResponseSuccess: 0,
                importResponseFail: 0
            };
        case GET_PROMOTION_EVENT_FOR_SUBMISSION:
            return {
                ...state,
                eventForSubmissionDetails: payload.eventForSubmissionDetails,
                retainState: false
            };
        case GET_PROMOTION_EVENT_HISTORY:
            return {
                ...state,
                eventHistoryDetails: payload.eventHistoryDetails,
                retainState: false
            };
        case CLEAR_PROMOTION_EVENTS:
            return {
                ...state,
                promotionEventsList: []
            };
        case RETAIN_PROMOTION_EVENTS:
            return {
                ...state,
                retainState: true
            };
        case CLEAR_PROMOTION_RETAIN_STATE:
            return {
                ...state,
                retainState: false,
                promotionEventsList: []
            }
        case SET_PROMOTION_EVENTS_PAGE_NO:
            return {
                ...state,
                retainState: false,
                currentPage: payload
            };
        case SET_PROMOTION_EVENTS_PAGE_SIZE:
            return {
                ...state,
                retainState: false,
                pageSize: payload
            };
        case UPLOAD_PROMOTION_FILE_RESPONSE:
            return {
                ...state,
                importResponseMessage: payload.importResponseMessage,
                importResponseData: payload.importResponseData,
                importResponseTotal: payload.importResponseTotal,
                importResponseSuccess: payload.importResponseSuccess,
                importResponseFail: payload.importResponseFail,
                isFileSubmitted: true,
                retainState: false
            };
        case CLEAR_PROMOTION_FILE_RESPONSE:
            return {
                ...state,
                importResponse: '',
                importResponseMessage: '',
                isFileSubmitted: false,
                importResponseData: [],
                importResponseTotal: 0,
                importResponseSuccess: 0,
                importResponseFail: 0
            };

        default:
            return state;
    }
}
