export const GET_PROMOTION_EVENTS = 'GET_PROMOTION_EVENTS';
export const CLEAR_PROMOTION_EVENTS = 'CLEAR_PROMOTION_EVENTS';
export const RETAIN_PROMOTION_EVENTS = 'RETAIN_PROMOTION_EVENTS';
export const SET_PROMOTION_EVENTS_PAGE_SIZE = 'SET_PROMOTION_EVENTS_PAGE_SIZE';
export const SET_PROMOTION_EVENTS_PAGE_NO = 'SET_PROMOTION_EVENTS_PAGE_NO';
export const CLEAR_PROMOTION_RETAIN_STATE = 'CLEAR_PROMOTION_RETAIN_STATE';

export const GET_PROMOTION_EVENT_FOR_SUBMISSION = 'GET_PROMOTION_EVENT_FOR_SUBMISSION';
export const GET_PROMOTION_EVENT_HISTORY = 'GET_PROMOTION_EVENT_HISTORY';

export const UPLOAD_PROMOTION_FILE_RESPONSE = 'UPLOAD_PROMOTION_FILE_RESPONSE';
export const CLEAR_PROMOTION_FILE_RESPONSE = 'CLEAR_PROMOTION_FILE_RESPONSE ';
