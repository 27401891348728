export const FETCH_SALES_INVOICES = 'FETCH_SALES_INVOICES';
export const CLEAR_SALES_INVOICES = 'CLEAR_SALES_INVOICES';
export const RETAIN_SALES_INVOICES_STATE = 'RETAIN_SALES_INVOICES_STATE';
export const RETAIN_SALES_INVOICE_DETAILS_STATE = 'RETAIN_SALES_INVOICE_DETAILS_STATE';
export const SET_SALES_INVOICES_PAGE_SIZE = 'SET_SALES_INVOICES_PAGE_SIZE';
export const SET_SALES_INVOICES_PAGE_NO = 'SET_SALES_INVOICES_PAGE_NO';

export const SET_SALES_INVOICES_SEARCH_FILTERS = 'SET_SALES_INVOICES_SEARCH_FILTERS';
export const FETCH_SALES_INVOICE_DETAILS = 'FETCH_SALES_INVOICE_DETAILS';

export const SET_RETAIN_SALES_INVOICE_DETAILS_STATE = 'SET_RETAIN_SALES_INVOICE_DETAILS_STATE'
export const INITIAL_SALES_INVOICE_STATE = 'INITIAL_SALES_INVOICE_STATE';
export const SET_INVOICE_FILTER_VALUES = 'SET_INVOICE_FILTER_VALUES';