export const GET_VENDOR_SKU = "GET_VENDOR_SKU";
export const GET_VENDOR_SKU_CSV = "GET_VENDOR_SKU_CSV";
export const RETAIN_VENDOR_SKU_STATE = "RETAIN_VENDOR_SKU_STATE";
export const RETAIN_VENDOR_SKU_STATE_CSV = "RETAIN_VENDOR_SKU_STATE_CSV";
export const CLEAR_RETAIN_VENDOR_SKU_STATE = "CLEAR_RETAIN_VENDOR_SKU_STATE";
export const CLEAR_VENDOR_SKU = "CLEAR_VENDOR_SKU";
export const SET_VENDOR_SKU_PAGE_SIZE = "SET_VENDOR_SKU_PAGE_SIZE";
export const SET_VENDOR_SKU_PAGE_NO = "SET_VENDOR_SKU_PAGE_NO";
export const SET_CSV_PAGE_NO = "SET_CSV_PAGE_NO";
export const SET_SELECT_VENDOR_SKU = "SET_SELECT_VENDOR_SKU";
export const SET_VENDOR_SKU_SEARCH_FILTERS = "SET_VENDOR_SKU_SEARCH_FILTERS";

export const GET_VENDOR_SKU_DETAIL = "GET_VENDOR_SKU_DETAIL";
export const CLEAR_VENDOR_SKU_DETAIL = "CLEAR_VENDOR_SKU_DETAIL";
export const RETAIN_VENDOR_SKU_DETAIL_STATE = "RETAIN_VENDOR_SKU_DETAIL_STATE";
export const CLEAR_RETAIN_VENDOR_SKU_DETAIL_STATE =
  "CLEAR_RETAIN_VENDOR_SKU_DETAIL_STATE";

export const SET_PRODUCT_DETAIL_VALUES = "SET_PRODUCT_DETAIL_VALUES";
export const CLEAR_PRODUCT_DETAIL_VALUES = "CLEAR_PRODUCT_DETAIL_VALUES";

export const SET_PRODUCT_PRICE_VALUES = "SET_PRODUCT_PRICE_VALUES";
export const CLEAR_PRODUCT_PRICE_VALUES = "CLEAR_PRODUCT_PRICE_VALUES";

export const SET_PRODUCT_IMAGE_VALUES = "SET_PRODUCT_IMAGE_VALUES";
export const CLEAR_PRODUCT_IMAGE_VALUES = "CLEAR_PRODUCT_IMAGE_VALUES";

export const CREATE_VENDOR_PRODUCT = "CREATE_VENDOR_PRODUCT";
export const CREATE_VENDOR_PRODUCT_STATUS_RESPONSE =
  "CREATE_VENDOR_PRODUCT_STATUS_RESPONSE";

export const UP_VENDOR_PRODUCT_RESPONSE_MESSAGE =
  "UP_VENDOR_PRODUCT_RESPONSE_MESSAGE";
export const CLEAR_PRODUCT_STATUS_RESPONSE = "CLEAR_PRODUCT_STATUS_RESPONSE";
export const FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES";
export const FETCH_PRODUCT_NEW_CATEGORIES = "FETCH_PRODUCT_NEW_CATEGORIES";
export const SET_PRODUCT_CATEGORY_VALUES = "SET_PRODUCT_CATEGORY_VALUES";
export const CLEAR_PRODUCT_CATEGORIES = "CLEAR_PRODUCT_CATEGORIES";

export const UPDATE_VENDOR_PRODUCT_STATUS_RESPONSE =
  "UPDATE_VENDOR_PRODUCT_STATUS_RESPONSE";
export const CLEAR_UPDATE_PRODUCT_STATUS_RESPONSE =
  "CLEAR_UPDATE_PRODUCT_STATUS_RESPONSE";

export const INITIAL_MANAGE_PRODUCT_STATE = "INITIAL_MANAGE_PRODUCT_STATE";

export const SET_PRODUCT_FILTER_VALUES = "SET_PRODUCT_FILTER_VALUES";
export const CLEAR_PRODUCT_FILTER_VALUES = "CLEAR_PRODUCT_FILTER_VALUES";

export const GET_PRICE_REVIEW_SKU = "GET_PRICE_REVIEW_SKU";
export const GET_PRICE_UPDATE_SINGLE_RESPONSE =
  "GET_PRICE_UPDATE_SINGLE_RESPONSE";
