import {
  FETCH_VENDOR_ORDERS,
  CLEAR_VENDOR_ORDERS,
  RETAIN_VENDOR_ORDER_STATE,
  CLEAR_VENDOR_ORDER_RETAIN_STATE,
  SET_VENDOR_ORDERS_PAGE_SIZE,
  SET_VENDOR_ORDERS_PAGE_NO,
  SET_SELECT_VENDOR_ORDER,
  CLEAR_SELECTED_ORDER_LIST,
  SET_VENDOR_ORDER_SEARCH_FILTERS,
  FETCH_VENDOR_ORDERS_DETAIL,
  CLEAR_VENDOR_ORDERS_DETAIL,
  RETAIN_VENDOR_ORDERS_DETAIL_STATE,
  UPDATE_VENDOR_ORDERS_DETAIL,
  UPDATE_ORDER_EXPORT_STATUS,
  CLEAR_STATUS_UPDATE_RESPONSE,
  SET_RETAIN_VENDOR_ORDERS_DETAIL_STATE,
  RETAIN_SHIPMENT_STATE,
  SET_RETAIN_SHIPMENT_STATE,
  GET_SHIPMENT,
  CLEAR_SHIPMENT_LIST,
  INITIAL_SALES_ORDER_STATE,
  FETCH_VENDOR_EXPORT_ORDERS,
  CREATE_ORDER_SHIPMENT,
  CLEAR_SHIPMENT_RESPONSE,
  REMOVE_ORDER_SHIPMENT,
  GET_COURIER_LIST,
  SET_ORDER_FILTER_VALUES,
  CLEAR_ORDER_FILTER_VALUES
} from './SalesOrdersConstants';

import moment from 'moment-timezone';
const initialState = {
  vendorOrdersList: [],
  vendorOrdersDetailList: '',
  isSelected: true,
  isProductSelectAll: false,
  retainState: false,
  retainVendorOrderDetailState: false,
  totalCount: 0,
  totalPages: 0,
  pageSize: 100,
  currentPage: 1,
  searchFilters: '',
  updatedVendorOrderList: '',
  selectedList: [],
  responseStatus: '',
  shipmentList: '',
  retainShipmentState: false,
  retainOrderInvoiceState: false,
  vendorOrdersExportList: '',
  shipmentResponse: '',
  exportResponse: '',
  courierList: '',
  orderFilterValues: {
    increment_ids: '',
    purchase_date_from: '',
    purchase_date_to: '',
    exported_status: '',
    status: ''
  }
};

export default function salesOrderReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_VENDOR_ORDERS:
      return {
        ...state,
        vendorOrdersList: [
          ...state.vendorOrdersList,
          ...payload.vendorOrdersList
        ],
        totalCount: payload.totalCount,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        retainState: true,
        selectedList: [],
        isProductSelectAll: false,
        responseStatus: '',
        retainShipmentState: false,
        shipmentList: ''
      };
    case FETCH_VENDOR_ORDERS_DETAIL:
      return {
        ...state,
        vendorOrdersDetailList: payload.vendorOrdersDetailList,
        retainShipmentState: false,
        cancelResponse: '',
        shipmentList: ''
      };
    case UPDATE_VENDOR_ORDERS_DETAIL:
      return {
        ...state,
       // updatedVendorOrderList: payload.updatedVendorOrderList,
        responseStatus: payload.responseStatus,
        cancelResponse:payload.cancelResponse,
        retainVendorOrderDetailState: payload.retainVendorOrderDetailState
      };
    case CLEAR_STATUS_UPDATE_RESPONSE:
      return {
        ...state,
        responseStatus: ''
      };
    case CLEAR_SHIPMENT_LIST:
      return {
        ...state,
        shipmentList: ''
      };
    case UPDATE_ORDER_EXPORT_STATUS:
      return {
        ...state,
        exportResponse: payload.exportResponse,
        retainState: false
      };
    case GET_SHIPMENT:
      return {
        ...state,
        shipmentList: payload.shipmentList,
        responseStatus: ''
      };
    case CLEAR_VENDOR_ORDERS:
      return {
        ...state,
        vendorOrdersList: []
        // currentPage: 1
      };

    case CLEAR_VENDOR_ORDERS_DETAIL:
      return {
        ...state,
        vendorOrdersDetailList: [],
        responseStatus: '',
        cancelResponse: ''
        // currentPage: 1
      };

    case SET_VENDOR_ORDERS_PAGE_SIZE:
      return {
        ...state,
        retainState: false,
        pageSize: payload
      };
    case SET_VENDOR_ORDERS_PAGE_NO:
      return {
        ...state,
        retainState: false,
        currentPage: payload
      };
    case SET_VENDOR_ORDER_SEARCH_FILTERS:
      return {
        ...state,
        retainState: false,
        searchFilters: payload,
        currentPage: 1
      };
    case SET_SELECT_VENDOR_ORDER:
      return {
        ...state,
        vendorOrdersList: setProductSelection(
          state.vendorOrdersList,
          payload.id,
          payload.isSelectAllMode,
          payload.isSelectAll
        ),
        selectedList: selectedProducts(state.vendorOrdersList),
        isProductSelectAll: payload.isSelectAll
      };
    case CLEAR_SELECTED_ORDER_LIST:
      return {
        ...state,
        selectedList: []
      };
    case RETAIN_VENDOR_ORDER_STATE:
      return {
        ...state,
        retainState: true
      };
    case CLEAR_VENDOR_ORDER_RETAIN_STATE:
      return {
        ...state,
        retainState: false
      };
    case RETAIN_VENDOR_ORDERS_DETAIL_STATE:
      return {
        ...state,
        retainVendorOrderDetailState: true
      };
    case SET_RETAIN_VENDOR_ORDERS_DETAIL_STATE:
      return {
        ...state,
        retainVendorOrderDetailState: false
      };

    case RETAIN_SHIPMENT_STATE:
      return {
        ...state,
        retainShipmentState: true
      };
    case SET_RETAIN_SHIPMENT_STATE:
      return {
        ...state,
        retainShipmentState: false
      };
    case FETCH_VENDOR_EXPORT_ORDERS:
      return {
        ...state,
        vendorOrdersExportList: payload.vendorOrdersExportList
      };
    case CREATE_ORDER_SHIPMENT:
      return {
        ...state,
        shipmentResponse: payload.shipmentResponse,
        retainShipmentState: false
      };
    case REMOVE_ORDER_SHIPMENT:
      return {
        ...state,
        shipmentResponse: payload.shipmentResponse,
        retainShipmentState: false
      };
    case CLEAR_SHIPMENT_RESPONSE:
      return {
        ...state,
        shipmentResponse: ''
      };
    case GET_COURIER_LIST:
      return {
        ...state,
        courierList: payload.courierList
      };
    case SET_ORDER_FILTER_VALUES:
      return {
        ...state,
        orderFilterValues: payload,
        retainState: true
      };
    case CLEAR_ORDER_FILTER_VALUES:
      return {
        ...state,
        orderFilterValues: null
      };
    case INITIAL_SALES_ORDER_STATE:
      return {
        ...state,
        vendorOrdersList: [],
        vendorOrdersDetailList: '',
        isSelected: true,
        isProductSelectAll: false,
        retainState: false,
        retainVendorOrderDetailState: false,
        totalCount: 0,
        totalPages: 0,
        pageSize: 50,
        currentPage: 1,
        searchFilters: '',
        updatedVendorOrderList: '',
        selectedList: [],
        responseStatus: '',
        shipmentList: '',
        retainShipmentState: false,
        retainOrderInvoiceState: false,
        vendorOrdersExportList: '',
        shipmentResponse: '',
        exportResponse: '',
        courierList: '',
        orderFilterValues: {
          increment_ids: '',
          purchase_date_from: '',
          purchase_date_to: '',
          exported_status: '',
          status: ''
        }
      };

    default:
      return state;
  }
}

const setProductSelection = (
  productList,
  productID,
  isSelectAllMode,
  isSelectAll
) => {
  const newList = [];
  console.log("SetProductSelect", productList, productID);
  if (isSelectAllMode) {
    productList.forEach((product, index) => {
      product.isSelected = isSelectAll;
      newList.push(product);
    });
  } else {
    productList.forEach((product, index) => {
      if (product.increment_id === productID) {
        product.isSelected = !product.isSelected;
      }
      newList.push(product);
    });
  }
  return newList;
};


const selectedProducts = (selectProductList) => {
  const selectedList = selectProductList.filter((order) => order.isSelected);
  const mArray = [];
  selectedList.forEach((obj) => {
    let container = {};

    container.order_id = obj.increment_id;
    container.created_at = moment.utc(obj.created_at).tz('Australia/Sydney').format('YYYY-MM-DD hh:mm:ss');
    container.grand_total = obj.grand_total;
    container.status = obj.status;
    container.shipping_charges = obj.shipping_amount;
    container.discount_amount = obj.discount_amount;


    if (obj.shipping_info)
    {
      // container.End_Customer_Name = obj.shipping_info.firstname ? obj.shipping_info.firstname: "" + " " + obj.shipping_info.lastname ? obj.shipping_info.lastname : "";
      container.End_Customer_Name = obj.shipping_info.firstname + " " + obj.shipping_info.lastname;
    container.street = obj.shipping_info.street ? obj.shipping_info.street : '';
    container.suburb = obj.shipping_info.city ? obj.shipping_info.city : "";
    container.state = obj.shipping_info.region ? obj.shipping_info.region : "";
    container.postcode = obj.shipping_info.postcode ? obj.shipping_info.postcode : "";
    container.Phone_number = obj.shipping_info.telephone ? obj.shipping_info.telephone: "";
    }

    if (obj.items && obj.items.length > 0) {
      obj.items.forEach((item) => {
        let container3 = { ...container };
        container3.sku = item.sku;
        container3.qty = item.qty;
        container3.price = item.price;
        container3.row_total = item.row_total;
        mArray.push(container3);
      })
    } else {
      mArray.push(container)
    }
  });
  return mArray;
}