import {
  GET_PRICE_REVIEW_PERIOD,
  CLEAR_PRICE_REVIEW_RESPONSE,
  RETAIN_PRICE_REVIEW_PERIOD,
  GET_PRICE_UPDATE_RESPONSE
} from './PriceReviewConstants';

const initialState = {
  priceReviewPeriodStatus: false,
  priceReviewPeriodStart: '',
  priceReviewPeriodEnd: '',
  priceReviewPeriodEffective: '',
  retainPriceReviewPeriodState: false,

  importResponse: '',
  importResponseMessage: '',
  isFileSubmitted: false,
  importResponseData: [],
  importResponseTotal: 0,
  importResponseAbnormal: 0,
  importResponseFail: 0
};

export default function PriceReviewReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_PRICE_REVIEW_PERIOD:
      return {
        ...state,
        priceReviewPeriodStatus: payload.priceReviewPeriodStatus,
        priceReviewPeriodStart: payload.priceReviewPeriodStart,
        priceReviewPeriodEnd: payload.priceReviewPeriodEnd,
        priceReviewPeriodEffective: payload.priceReviewPeriodEffective,
        retainPriceReviewPeriodState: false
      };
    case RETAIN_PRICE_REVIEW_PERIOD:
      return {
        ...state,
        retainPriceReviewPeriodState: true
      };
    case GET_PRICE_UPDATE_RESPONSE:
      return {
        ...state,
        importResponseMessage: payload.importResponseMessage,
        importResponseData: payload.importResponseData,
        importResponseTotal: payload.importResponseTotal,
        importResponseAbnormal: payload.importResponseAbnormal,
        importResponseFail: payload.importResponseFail,
        retainPriceReviewPeriodState: true,
        isFileSubmitted: true
      };
    case CLEAR_PRICE_REVIEW_RESPONSE:
      return {
        ...state,
        importResponse: '',
        importResponseMessage: '',
        isFileSubmitted: false,
        importResponseData: [],
        importResponseTotal: 0,
        importResponseAbnormal: 0,
        importResponseFail: 0
      };

    default:
      return state;
  }
}
