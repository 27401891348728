import {
  GET_PRICE_REVIEW_PERIOD,
  CLEAR_PRICE_REVIEW_RESPONSE,
  GET_PRICE_UPDATE_RESPONSE
} from './PriceReviewConstants';

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../../../../reducers/asyncReducer';

import { toast } from 'react-toastify';

import { VendorServiceObj } from '../../service/api.service';

export function priceReviewPeriod(vendorID) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    VendorServiceObj.getPriceReviewPeriod(vendorID)
      .then(function (response) {
        //console.log('getPriceReviewPeriod Response', response);

        let priceReviewPeriodStatus = false;
        if (response.res.data.status === 'open') priceReviewPeriodStatus = true;
   
        if (response.res) {
          dispatch({
            type: GET_PRICE_REVIEW_PERIOD,
            payload: {
              priceReviewPeriodStatus,
              priceReviewPeriodStart : response.res.data.open_time,
              priceReviewPeriodEnd : response.res.data.close_time,
              priceReviewPeriodEffective : response.res.data.effective_time
            }
          });
          //console.log('importProducts Response', response.res);
        }

        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        //  console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function savePriceReview(vendorID, skuList) {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    
    VendorServiceObj.savePriceNew(vendorID, skuList)
      .then(function (response) {
       // console.log('savePriceReview Response', response);

     /*   console.log('importResponseMessage Response',  importResponseMessage,
       importResponseData,
       importResponseTotal,
       importResponseAbnormal,
       importResponseFail); */

        if (response.res) {
            if(!response.res.success) {
              toast.error(response.res.error);
              return;
            }
            //need proper null check; currently just flow it to error catch
            const list = response.res.data.price_update;
  
            const mArray = [];
            if (list && list.length !== 0)
              list.forEach((obj) => {
                let container = {};
  
                container.sku = obj.sku;
                container.new_price = obj.new_price? obj.new_price: "";
                container.new_rrp = obj.new_rrp? obj.new_rrp: "";
                container.notes = `"${obj.notes}"`;
                container.status = obj.status;
                // container.current_price = obj.current_price? obj.current_price : "";&& obj.new_zone_rates.data
                if (obj.new_zone_rates ) {
                  try {
                    const jsonRates = obj.new_zone_rates;
  
                    container.act = jsonRates.act? jsonRates.act : "";
                    container.nsw_m = jsonRates.nsw_m? jsonRates.nsw_m: "";
                    container.nsw_r = jsonRates.nsw_r? jsonRates.nsw_r: "";
                    container.nt_m = jsonRates.nt_m? jsonRates.nt_m : "";
                    container.nt_r = jsonRates.nt_r? jsonRates.nt_r : "";
                    container.qld_m = jsonRates.qld_m? jsonRates.qld_m: "";
                    container.qld_r = jsonRates.qld_r? jsonRates.qld_r: "";
                    container.remote = jsonRates.remote? jsonRates.remote: "";
                    container.sa_m = jsonRates.sa_m? jsonRates.sa_m: "";
                    container.sa_r = jsonRates.sa_r? jsonRates.sa_r : "";
                    container.tas_m = jsonRates.tas_m? jsonRates.sa_r : "";
                    container.tas_r = jsonRates.tas_r? jsonRates.tas_r: "";
                    container.vic_m = jsonRates.vic_m? jsonRates.vic_m: "";
                    container.vic_r = jsonRates.vic_r? jsonRates.vic_r : "";
                    container.wa_m = jsonRates.wa_m? jsonRates.wa_m: "";
                    container.wa_r = jsonRates.wa_r? jsonRates.wa_r: "";

                    if(jsonRates.nz) container.nz = jsonRates.nz
  
                  } catch (e) {
                    console.log(e)
                  }

                }
  
                mArray.push(container)
  
              });
  
            //  console.log("Array", mArray);


          dispatch({
            type: GET_PRICE_UPDATE_RESPONSE,
            payload: {
              importResponseMessage : response.res.message,
              importResponseData : mArray,
              importResponseTotal : response.res.data.summary.total,
              importResponseAbnormal : response.res.data.summary.abnormal,
              importResponseFail : response.res.data.summary.fail
            }
          });
          // console.log('importProducts Response', response.res);
        }

        dispatch(asyncActionFinish());
      })
      .catch(function (error) {
        //  console.error(error.data);
        dispatch(asyncActionError(error));
      });
  };
}

export function clearPriceReviewResponse() {
  return {
    type: CLEAR_PRICE_REVIEW_RESPONSE
  };
}
