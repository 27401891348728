import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Header, SidebarCollapsed, Sidebar } from '../../layout-components';

const CollapsedSidebar = (props) => {
  const {
    children,
    contentBackground,
    sidebarToggleMobile,
    headerDrawerToggle,
    headerSearchHover,
    titleHeading
  } = props;

  console.log("props",props);

  return (
    <>
      <div
        className={clsx(
          'app-wrapper app-sidebar-collapsed app-sidebar-fixed app-header-fixed vh-100',
          contentBackground,
          {
            'app-sidebar-mobile-open': sidebarToggleMobile,
            'header-drawer-open': headerDrawerToggle,
            'search-wrapper-open': headerSearchHover
          }
        )}>
        <Sidebar />
        <div className="app-main">
          {/* <Header titleHeading={titleHeading}/> */}
         
            <div>{children}</div>
        
        </div>
      </div>
    </>
  );
};

CollapsedSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  contentBackground: state.ThemeOptions.contentBackground,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  headerSearchHover: state.ThemeOptions.headerSearchHover
});

export default connect(mapStateToProps)(CollapsedSidebar);
