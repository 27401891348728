import {
  FETCH_SALES_FIGURES,
  CLEAR_SALES_FIGURES,
  RETAIN_SALES_FIGURES_STATE,
  FETCH_GRAPH_ORDER,
  RETAIN_GRAPH_STATE
} from './DashboardConstants';

const initialState = {
  creditAmount: 0,
  lifeTimeSales: 0,
  averageOrders: 0,
  totalProducts: 0,
  retainState: false,
  retainStateGraph: false,
  periodVal: '24h',
  xAxisValues: [],
  yAxisValues: []
};

export default function DashboardReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_SALES_FIGURES:
      return {
        ...state,
        creditAmount: payload.creditAmount,
        lifeTimeSales: payload.lifeTimeSales,
        averageOrders: payload.averageOrders,
        totalProducts: payload.totalProducts,
        retainState: true
      };
    case FETCH_GRAPH_ORDER:
      return {
        ...state,
        xAxisValues: payload.xAxisValues,
        yAxisValues: payload.yAxisValues,
        periodVal: payload.periodValue,
        retainStateGraph: true
      };
    case CLEAR_SALES_FIGURES:
      return {
        ...state,
        creditAmount: 0,
        lifeTimeSales: 0,
        averageOrders: 0,
        retainState: false,
        retainStateGraph: false,
        periodVal: '24h',
        xAxisValues: [],
        yAxisValues: []
      };
    case RETAIN_SALES_FIGURES_STATE:
      return {
        ...state,
        retainState: true
      };
    case RETAIN_GRAPH_STATE:
      return {
        ...state,
        retainStateGraph: true
      };

    default:
      return state;
  }
}
