import {
    NEW_PRODUCT_RESPONSE,
    CLEAR_NEW_PRODUCT_RESPONSE 
  } from './NewProductsLaunchConstants';
  
  const initialState = {
    newProductResponse: '',
    newProductResponseMessage: '',
    isNewProductSubmitted: false
  };
  
  
  export default function NewProductsLaunchReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
     
      case NEW_PRODUCT_RESPONSE:
        return {
          ...state,
          newProductResponse: payload.newProductRes,
          newProductResponseMessage: payload.newProductResMessage,
          isNewProductSubmitted: true
        };
        case CLEAR_NEW_PRODUCT_RESPONSE:
          return {
            ...state,
            newProductResponse: '',
            newProductResponseMessage: '',
            isNewProductSubmitted: false
          };
     
      default:
        return state;
    }
  }
  
  